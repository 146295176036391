import React, { useEffect, useState } from "react";
import { angularize } from "react-in-angularjs";
import getDuService from "ServiceBroker";
import { AccountService } from "types/AccountServiceResponse";
const StudentAccountCenter = () => {
  const [accountBalance, setAccountBalance] = useState<string | undefined>(
    "$0"
  );
  const [navlink, setNavlink] = useState("");
  const getAccountBalance = async () => {
    try {
      const accountBal = (await getDuService({
        service: "accountService",
        method: "getAccBalance",
      })) as AccountService;

      setAccountBalance(accountBal.balance);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getAccountBalance();
    if (window.location.origin == "https://students.umgc.edu") {
      setNavlink("https://secure.touchnet.com/C22578_tsa/web/caslogin.jsp");
    } else {
      setNavlink(
        "https://myapps.microsoft.com/signin/0bdeb548-7c1f-47b0-b983-500edce6c7c0?tenantId=11086010-9ca2-4ede-a290-9a09a2cb57bb"
      );
    }
  }, []);
  return (
    <section
      aria-labelledby="Student Account Center"
      id="du-class-finances-landing-Student-Account-Center"
      className="border-box -webkit-shadow-[5px 5px 5px 0px rgba(0,0,0,0.1)] moz-shadow-[5px_5px_5px_0px_rgba(255,255,0,1)] relative  mb-[1.25rem] mr-[1.75rem] bg-[#ffffff] p-[1.25rem]"
      style={{
        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.1)",
        width: "95%",
      }}
    >
      <div className="flex flex-col justify-between sm:flex-row">
        <div className="text-left">
          <h3
            className="text-[1.5em]"
            style={{
              fontFamily: "'Roboto', Tahoma, sans-serif",
              fontWeight: "300",
              lineHeight: "1.4em",
            }}
          >
            Student Account Center
          </h3>
        </div>
        <div className="mt-4 text-left sm:mt-0 sm:text-right">
          <h3
            className="text-[1.5em]"
            style={{
              fontFamily: "'Roboto', Tahoma, sans-serif",
              fontWeight: "300",
              lineHeight: "1.4em",
            }}
          >
            Current Balance: {accountBalance}
          </h3>
        </div>
      </div>

      <br />
      <p className="font-sans text-[0.9735rem] font-normal leading-[1.6em] text-[#3c3c43]">
        Easily manage your payments and account. Check your account details,
        make payments, track deadlines, view payment history, and view 1098-Ts
        by visiting your Student Account Center.
      </p>
      <br />
      <div className="flex-justify-between">
        <div className="text-left">
          <a
            id="visitStudentAccountCenter"
            target="blank"
            href={navlink}
            className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
          >
            Visit Student Account Center
          </a>
          <br />
          <a
            id="learnMoreAboutUMGCPaymentOptions"
            target="blank"
            href="http://www.umgc.edu/current-students/finances/payments/index.cfm"
            className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
          >
            Learn More About UMGC Payment Options
          </a>
          <br />
          <a
            id="setUpDirectDeposit"
            href="#/bankAccounts"
            className="relative cursor-[url(hand.cur),_pointer] text-[0.9375rem] font-bold text-[#003e6a] hover:underline"
          >
            Set Up Direct Deposit
          </a>
        </div>
      </div>
    </section>
  );
};

angularize(
  StudentAccountCenter,
  "studentAccountCenterReact",
  angular.module("duApp"),
  {}
);
export default StudentAccountCenter;
